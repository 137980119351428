.about-me-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 30px 50px 0px 20px;
  padding: 100px 0 200px 0;
}
.about-myself-header {
  font-family: "Roboto Mono", monospace;
  text-align: left;
  color: #ffa500;
  margin-bottom: 20px;
  text-align: center;
}
.about-myself-header::before,
.about-myself-header::after {
  content: " ";
  margin: 0 10px 10px 10px;
  display: inline-block;
  border: 1px solid #ffa500;
  width: 50px;
}
.about-myself-content {
  font-family: "Montserrat", sans-serif;
  color: #8892b0;
  font-size: 16px;
  width: 70%;
  margin-left: 30px;
  text-align: justify;
}
.Denitsa-photo {
  height: 300px;
  justify-content: center;
  padding: 3px;
  margin-top: 20px;
  box-shadow: #495670 0px 0px 0px 2px inset,
    rgb(255, 255, 255) 10px -10px 0px -3px, #ffa60085 10px -10px,
    rgb(255, 255, 255) 20px -20px 0px -3px;
}

@media screen and (max-width: 500px) {
  .about-me-div {
    width: 100%;
    margin-left: 0px;
  }
  .about-myself-header {
    font-size: 16px;
  }
  .about-myself-header::before,
  .about-myself-header::after {
    margin: 0 5px 3px 5px;
    width: 30px;
  }
  .Denitsa-photo {
    height: 200px;
    margin-left: 40px;
    margin-top: 50px;
  }
}
