.landing-page-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 100px 10px 200px 80px;
  padding: 100px 0;
}

.introduction-1 {
  font-family: "Roboto Mono", monospace;
  font-size: 22px;
  margin-bottom: 20px;
  color: #ffa500;
  margin-left: 50px;
}

.introduction-2 {
  font-family: "Roboto Mono", monospace;
  color: #233554;
  font-size: 56px;
}

.introduction-3 {
  font-family: "Roboto Mono", monospace;
  color: #495670;
  font-size: 46px;
  margin-top: 10px;
}
.introduction-4 {
  font-family: "Montserrat", sans-serif;
  color: #8892b0;
  margin-top: 30px;
  font-size: 22px;
  width: 600px;
}

.button-main-page {
  margin-top: 80px;
  margin-left: 60px;
  height: 50px;
  width: 130px;
  border: 1px solid #ffa500;
  border-radius: 3px;
  background-color: white;
  color: #ffa500;
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
  padding: 5px;
}
.button-main-page:hover {
  color: #495670;
  border-color: #495670;
}

@media screen and (max-width: 800px) {
  .introduction-4 {
    width: 90%;
  }
  .landing-page-div {
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  .introduction-1 {
    font-size: 20px;
    margin-left: 0;
  }
  .introduction-2 {
    font-size: 40px;
  }
  .introduction-3 {
    font-size: 30px;
  }
  .introduction-4 {
    font-size: 16px;
    width: 300px;
  }
}
