footer {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.footer-text {
  font-family: "Montserrat", sans-serif;
  color: #254e7b;
  margin-top: 10px;
  font-size: 9px;
}
