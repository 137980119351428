.projects-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.project-post-title-wraper,
.project-post-title-wraper-2,
.project-post-title-wraper-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 40px 120px;
  box-shadow: #495670 0px 0px 0px 2px inset,
    rgb(255, 255, 255) 10px -10px 0px -3px, #ffa60085 10px -10px,
    rgb(255, 255, 255) 20px -20px 0px -3px;
}

.project-post-title-wraper-2 {
  margin: 80px 0;
}

.aura-img,
.bluedge-img,
.real-estate-img {
  max-width: 780px;
}

.projects-header {
  font-family: "Roboto Mono", monospace;
  text-align: left;
  color: #ffa500;
  margin-bottom: 40px;
  font-size: 40px;
}
.projects-header::before,
.projects-header::after {
  content: " ";
  margin: 0 10px 10px 10px;
  display: inline-block;
  border: 1px solid #ffa500;
  width: 50px;
}

.project-post-link,
.project-post-link-2,
.project-post-link-3 {
  letter-spacing: 1px;
  font-size: 30px;
  color: #233554;
  font-family: "Roboto Mono", monospace;
  text-decoration: none;
  margin-bottom: 30px;
}

.project-post-image {
  margin-top: 30px;
}

@media screen and (max-width: 1120px) {
  .project-post-title-wraper,
  .project-post-title-wraper-2,
  .project-post-title-wraper-3 {
    padding: 40px 60px;
  }
  .aura-img,
  .bluedge-img,
  .real-estate-img {
    max-width: 480px;
  }
}

@media screen and (max-width: 768px) {
  .projects-header {
    font-size: 25px;
  }
  .projects-header::after,
  .projects-header::before {
    margin: 0 10px 5px 10px;
    width: 30px;
  }
  .aura-img,
  .bluedge-img,
  .real-estate-img {
    max-width: 350px;
  }
}

@media screen and (max-width: 600px) {
  .projects-header {
    font-size: 16px;
  }
  .projects-header::after,
  .projects-header::before {
    margin: 0 5px 5px 5px;
    width: 20px;
  }

  .project-post-title-wraper,
  .project-post-title-wraper-2,
  .project-post-title-wraper-3 {
    padding: 10px 20px;
  }
  .aura-img,
  .bluedge-img,
  .real-estate-img {
    max-width: 250px;
  }
}
@media screen and (max-width: 395px) {
  .project-post-title-wraper,
  .project-post-title-wraper-2,
  .project-post-title-wraper-3 {
    padding: 5px;
  }
}
