.sentance-bottom {
  font-family: "Roboto Mono", monospace;
  color: #ffa500;
  font-size: 30px;
  margin-top: 100px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
  .sentance-bottom {
    font-size: 20px;
  }
}

@media screen and (max-width: 425px) {
  .sentance-bottom {
    font-size: 16px;
  }
}
