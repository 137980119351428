nav {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
/* .navigation-letter {
  font-family: 'Roboto Mono', monospace;
  color: #233554;
  font-size: 56px;
  width: 60px;
  text-align: center;
  margin: 7px 5px;
  color: #495670;
}
.a-nav {
  color: #495670;
}
.navigation-letter:hover {
  color: #ffa500;
} */

.resume {
  height: 50px;
  margin: 10px 5px;
  width: 130px;
  border: 1px solid #495670;
  border-radius: 3px;
  background-color: #fff;
}
.resume-link {
  background-color: #fff;
  color: #495670;
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
  text-decoration: none;
  padding: 5px;
}
.resume:hover,
.resume-link:hover {
  color: #ffa500;
  border-color: #ffa500;
}
