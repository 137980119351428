* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;

  /* overflow: hidden; */
  background-color: white;
  scroll-behavior: smooth;
}
